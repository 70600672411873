import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, IconButton } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import SecurityIcon from '@material-ui/icons/Security';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';  // Import your logo
import '../App.css';

const Sidebar = ({ open, handleDrawerClose }) => {
    return (
        <Drawer
            variant="persistent"
            open={open}
            classes={{ paper: 'drawerPaper' }}
        >
            <div className="drawerHeader">
                <img src={logo} alt="Logo" className="logo" />  {/* Display the logo */}
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeft />
                </IconButton>
            </div>
            <Divider />
            <List>
                <ListItem button component={Link} to="/dashboard">
                    <ListItemIcon><SecurityIcon /></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </List>
        </Drawer>
    );
};

export default Sidebar;
