import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [keys, setKeys] = useState([]);
    const [open, setOpen] = useState(false);
    const [keyToDelete, setKeyToDelete] = useState(null);
    const [visibleKeys, setVisibleKeys] = useState({});
    const [countdown, setCountdown] = useState(30);
    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [serverError, setServerError] = useState(false);
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
            return;
        }

        const fetchEmailByToken = async () => {
            try {
                const response = await axios.post('https://apiauthy.coneiz.com/gte', { token });
                setEmail(response.data.email);
            } catch (error) {
                navigate('/');
            }
        };

        fetchEmailByToken();
    }, [navigate]);

    useEffect(() => {
        const fetchKeys = async () => {
            try {
                const response = await fetch('https://apiauthy.coneiz.com/view-key-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email })
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch keys');
                }
                const data = await response.json();
                setKeys(data.keys);
                setLoading(false);
                setServerError(false);
            } catch (error) {
                setLoading(false);
                setServerError(true);
            }
        };

        fetchKeys();
        const interval = setInterval(fetchKeys, 30000); // Refresh every 30 seconds
        return () => clearInterval(interval);
    }, [email]);

    useEffect(() => {
        const fetchTotpCodes = async () => {
            const updatedKeys = await Promise.all(keys.map(async (keyData) => {
                try {
                    const response = await axios.post('https://apiauthy.coneiz.com/get-totp-code', { secret: keyData.key });
                    return { ...keyData, totp: response.data.totp };
                } catch (error) {
                    return { ...keyData, totp: 'Error fetching TOTP' };
                }
            }));
            setKeys(updatedKeys);
        };

        const timer = setInterval(() => {
            setCountdown(prev => prev > 0 ? prev - 1 : 30);

            if (countdown === 0) {
                fetchTotpCodes();
                setCountdown(30);
            }
        }, 1000);

        if (keys.length > 0) {
            fetchTotpCodes();

            return () => {
                clearInterval(timer);
            };
        }
    }, [keys, countdown]);

    const handleDelete = async () => {
        try {
            await axios.post(`https://apiauthy.coneiz.com/delete-key-email?email=${email}&key=${keyToDelete}`);
            setKeys(keys.filter(k => k.key !== keyToDelete));
            setOpen(false);
            setSuccessOpen(true);
        } catch (error) {
            setOpen(false);
            setErrorMessage('Key deleted successfully');
            setErrorOpen(true);
            navigate(0);
        }
    };

    const handleClickOpen = (key) => {
        setKeyToDelete(key);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setKeyToDelete(null);
    };

    const handleCloseSuccess = () => {
        setSuccessOpen(false);
        navigate(0);
    };

    const handleCloseError = () => {
        setErrorOpen(false);
    };

    const toggleVisibility = (key) => {
        setVisibleKeys(prevState => ({ ...prevState, [key]: !prevState[key] }));
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <main className={`content ${loading ? 'blur-background' : ''}`}>
            <div className="toolbar" />
            <Container>
                <Grid container spacing={3}>
                    {keys.map((keyData, index) => (
                        <Grid item xs={12} md={6} lg={4} key={index}>
                            <Paper className="paper">
                                <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
                                    <div className="key-info">
                                        <div>{keyData.key_name}:</div>
                                        <div className={`key-text ${visibleKeys[keyData.key] ? '' : 'blur-text'}`}>
                                            {keyData.key}
                                        </div>
                                        <div>{keyData.totp}</div>
                                    </div>
                                    <div className="icons">
                                        <IconButton
                                            onClick={() => toggleVisibility(keyData.key)}
                                            aria-label="toggle visibility"
                                            size="small"
                                        >
                                            {visibleKeys[keyData.key] ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
                                        </IconButton>
                                        <IconButton onClick={() => handleClickOpen(keyData.key)} aria-label="delete" size="small">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </Box>
                                <div className="progress-bar" style={{ width: `${(30 - countdown) / 30 * 100}%` }} />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ style: { background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', color: 'white', borderRadius: '8px' } }}
            >
                <DialogTitle id="alert-dialog-title">{"Delete Key"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ color: 'white' }}>
                        Once you delete this key, it will be unable to retrieve.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        style={{
                            color: '#EDE8E8',
                            backgroundColor: 'transparent',
                            textTransform: 'none'
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = 'black'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        style={{
                            color: '#EDE8E8',
                            backgroundColor: 'transparent',
                            textTransform: 'none'
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = 'black'}
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                        autoFocus
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={successOpen}
                onClose={handleCloseSuccess}
                PaperProps={{ style: { background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', color: 'white', borderRadius: '8px' } }}
            >
                <DialogTitle>{"Success"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: 'white' }}>
                        Key deleted successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSuccess} style={{ color: 'white' }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={errorOpen}
                onClose={handleCloseError}
                PaperProps={{ style: { background: 'linear-gradient(135deg, #ff5f6d 0%, #ffc371 100%)', color: 'white', borderRadius: '8px' } }}
            >
                <DialogTitle>{"Warning"}</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: 'white' }}>
                    Key deleted successfully
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseError} style={{ color: 'white' }}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={loading || serverError}
                PaperProps={{ style: { background: 'rgba(0, 0, 0, 0.8)', color: 'white', borderRadius: '8px', textAlign: 'center' } }}
            >
                <DialogContent>
                    <CircularProgress style={{ color: 'white', marginBottom: '16px' }} />
                    <DialogContentText style={{ color: 'white' }}>
                        {serverError ? "Server is having issues. We are trying to connect for you." : "Loading..."}
                    </DialogContentText>
                </DialogContent>
                {serverError && (
                    <DialogActions>
                        <Button onClick={handleLogout} style={{ color: 'white' }}>
                            Logout
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </main>
    );
};

export default Dashboard;
