import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import AddKeyModal from './AddKeyModal';
import '../App.css';

const Header = ({ handleDrawerOpen, handleToggleTheme, darkMode }) => {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const token = localStorage.getItem('token');

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/authentication/sign-in');
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <>
            <AppBar position="fixed" className="appBar">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
                        Coneiz Authy
                    </Typography>
                    <IconButton edge="end" color="inherit" aria-label="toggle theme" onClick={handleToggleTheme}>
                        {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                    </IconButton>
                    <IconButton edge="end" color="inherit" aria-label="add key" onClick={handleModalOpen}>
                        <AddIcon />
                    </IconButton>
                    <IconButton edge="end" color="inherit" aria-label="logout" onClick={handleLogout}>
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <AddKeyModal open={modalOpen} handleClose={handleModalClose} token={token} />
        </>
    );
};

export default Header;
