import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, TextField, Button, Box, Typography, Snackbar, Alert } from '@mui/material';

function Login() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleRequestOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://apiauthy.coneiz.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setStep(2);
        setSnackbarMessage('OTP sent to your email.');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(data.error);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('An error occurred. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleResendOTP = async () => {
    try {
      const response = await fetch('https://apiauthy.coneiz.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage('OTP resent to your email.');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(data.error);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('An error occurred. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://apiauthy.coneiz.com/login/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token);
        setSnackbarMessage('Login successful.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        navigate('/dashboard');
      } else {
        setSnackbarMessage(data.error + " " + "or not Registered");
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('An error occurred. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ backgroundImage: 'url(https://github.com/creativetimofficial/material-dashboard-react/blob/main/src/assets/images/bg-sign-in-basic.jpeg?raw=true)', backgroundSize: 'cover' }}
    >
      <Card sx={{ p: 4, maxWidth: 400 }}>
        <Box textAlign="center" mb={2}>
          <Typography variant="h4">{step === 1 ? 'Welcome back' : 'Enter OTP'}</Typography>
          <Typography variant="subtitle1">
            {step === 1 ? 'Enter your email to receive an OTP' : 'Enter the OTP sent to your email'}
          </Typography>
        </Box>
        <Box component="form" onSubmit={step === 1 ? handleRequestOTP : handleVerifyOTP}>
          <TextField
            label="Email"
            variant="standard"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            disabled={step === 2}
          />
          {step === 2 && (
            <>
              <TextField
                label="OTP"
                variant="standard"
                fullWidth
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                margin="normal"
              />
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={handleResendOTP}
                sx={{ mt: 1 }}
              >
                Resend OTP
              </Button>
            </>
          )}
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            {step === 1 ? 'Request OTP' : 'Verify OTP'}
          </Button>
        </Box>
        {step === 1 && (
          <Box textAlign="center" mt={2}>
            <Typography variant="body2">
              Don't have an account?{' '}
              <Typography component="a" href="/authentication/sign-up" color="primary">
                Sign Up
              </Typography>
            </Typography>
          </Box>
        )}
      </Card>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Login;
