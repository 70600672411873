import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AlertModal = ({ open, handleClose, message }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    ...style,
                    bgcolor: 'green',
                    background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
                    color: 'white',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {message}
                </Typography>
                <Button variant="contained" color="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

const AddKeyModal = ({ open, handleClose }) => {
    const [key, setKey] = useState('');
    const [key_name, setName] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
            return;
        }

        const fetchEmailByToken = async () => {
            try {
                const response = await axios.post('https://apiauthy.coneiz.com/gte', { token });
                setEmail(response.data.email);
            } catch (error) {
                // console.error('Error fetching email by token:', error);
                navigate('/');
            }
        };

        fetchEmailByToken();
    }, [navigate]);

    const handleAddKey = async () => {
        const trimmedKey = key.replace(/\s/g, '');
        const trimmedKeyName = key_name.replace(/\s/g, '');
        
        try {
            const response = await axios.post(`https://apiauthy.coneiz.com/add-key-email?key=${trimmedKey}&key_name=${trimmedKeyName}&email=${email}`);
            if (response.data.message) {
                setAlertMessage('Key added successfully');
                setAlertOpen(true);
                handleClose();
                setTimeout(() => navigate(0), 2000); // Delay to show the alert modal before reloading
            }
        } catch (error) {
            // console.error('Error adding key:', error);
            setAlertMessage('Failed to add key');
            setAlertOpen(true);
        }
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <TextField
                        label="Name"
                        fullWidth
                        value={key_name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        label="Key"
                        fullWidth
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                        margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleAddKey}>
                        Add Key
                    </Button>
                </Box>
            </Modal>
            <AlertModal open={alertOpen} handleClose={handleAlertClose} message={alertMessage} />
        </>
    );
};

export default AddKeyModal;
