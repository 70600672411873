import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import UserProfile from './pages/UserProfile';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import PrivateRoute from './pages/PrivateRoute';
import ForgotPassword from './pages/ForgotPassword';


import './App.css';

const App = () => {
    const [open, setOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(() => {
        return localStorage.getItem('darkMode') === 'true';
    });

    const theme = useMemo(() =>
        createTheme({
            palette: {
                mode: darkMode ? 'dark' : 'light',
            },
        }),
        [darkMode],
    );

    useEffect(() => {
        localStorage.setItem('darkMode', darkMode);
    }, [darkMode]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleToggleTheme = () => {
        setDarkMode(!darkMode);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <AppContent
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    handleToggleTheme={handleToggleTheme}
                    darkMode={darkMode}
                />
            </Router>
        </ThemeProvider>
    );
};

const AppContent = ({ open, handleDrawerOpen, handleDrawerClose, handleToggleTheme, darkMode }) => {
    const location = useLocation();
    const hideSidebarAndHeader = location.pathname === '/authentication/sign-in' || location.pathname === '/authentication/sign-up' || location.pathname === '/forget-password';

    return (
        <div style={{ display: 'flex' }}>
            {!hideSidebarAndHeader && (
                <>
                    <Header handleDrawerOpen={handleDrawerOpen} handleToggleTheme={handleToggleTheme} darkMode={darkMode} />
                    <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
                </>
            )}
            <div className={open && !hideSidebarAndHeader ? "content shiftContent" : "content"}>
                <Routes>
                    <Route path="/" element={
                         <PrivateRoute>  
                         <Dashboard />
                     </PrivateRoute>
                        } />
                    <Route 
                        path="/dashboard" 
                        element={
                            <PrivateRoute>  
                                <Dashboard />
                            </PrivateRoute>
                        } 
                    />
                    <Route path="/authentication/sign-up" element={<SignUp />} />
                    <Route path="/authentication/sign-in" element={<Login />} />
                    
                </Routes>
            </div>
        </div>
    );
};
//<Route path="/user-profile" element={<UserProfile />} />
//<Route path="/authentication/forgot-password" element={<ForgotPassword />} />
export default App;
